import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import LoaderComponent from '../../elements/LoaderComponent';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import Avatar from '@mui/material/Avatar';
import { DateTime } from "luxon";

import ResetPassword from "../Dialogs/ResetPassword";
import DeleteElement from "../Dialogs/DeleteElement";
import ActivateElement from "../Dialogs/ActivateElement";
import DeactivateElement from "../Dialogs/DeactivateElement";

import { AiFillStar } from "react-icons/ai";
import { IoMdWarning } from "react-icons/io";

import { TrashIcon,
         UserActivateIcon,
         UserDesactivateIcon } from '../../../helpers/icons';

import { PiPasswordBold } from "react-icons/pi";
import { BiTrash, BiPencil } from "react-icons/bi";
import { HiEye } from "react-icons/hi2";

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useLocation } from 'react-router-dom';

import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { onSelectAllClick, 
          order, 
          orderBy, 
          numSelected, 
          rowCount, 
          onRequestSort, 
          headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorteable ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            :
            headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const WrapperStyled = styled.div`
  .store{
    display: flex;
    flex-direction: row;
    align-items: center;
    .warning{
      display: flex;
      color: red;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  color: ${props => `rgb(${props.pancolors.black_overwhite})`}!important;

  .loader{
    margin-top: 15px;
    padding-left: 15px;
  }
  .inner_avatar{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  .icon{
      fill: #FFFFFF!important;
  }
  .MuiToolbar-root{
      padding-left: 15px;
  }
  .MuiTableRow-root{
      &.Mui-selected{
        background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          :hover{
            background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
      &.MuiTableRow-hover{
          :hover{
            background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
  }

  .MuiTableCell-body{
    color: ${props => `rgb(${props.pancolors.black_overwhite})`}!important;
  }

  .bt_bulk_delete{

  }
  .bt_bulk_activate{
      margin-left: 10px;
  }
  .bt_bulk_desactivate{
      margin-left: 10px;
  }
  .Mui-checked{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
  .MuiCheckbox-indeterminate{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
  .field_name{
    display: flex;
    flex-direction: row;
    .is_captain{
      display: flex;
      padding-top: 3px;
      margin-right: 5px;
      color: gold;
    }
  }
    
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.pancolors.red})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.pancolors.dots})`};
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
  }
`

const ToolbarStyled = styled(Toolbar)`

  &.MuiToolbar-root{
    background-color: ${props => `${props.numselected ? "rgba("+props.pancolors.dots+", 0.03)" : "transparent"}` }!important;
  }

`

function EnhancedTableToolbar(props) {
  
  const { numSelected,
          title,
          colors,
          panColors,
          selected,
          setSelected,
          setRows,
          getData,
          page,
          rowsPerPage,
          filters } = props;

  const [ titleDeleteText, setTitleDeleteText ] = useState("");
  const [ titleActivateText, setTitleActivateText ] = useState("");
  const [ titleDeactivateText, setTitleDeactivateText ] = useState("");

  const [ elementToDelete, setElementToDelete ] = useState("");
  const [ elementToActivate, setElementToActivate ] = useState("");
  const [ elementToDeactivate, setElementToDeactivate ] = useState("");

  const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
  const [ activateElementOpen, setActivateElementOpen ] = useState(false);
  const [ deactivateElementOpen, setDeactivateElementOpen ] = useState(false);

  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ isActivated, setIsActivated ] = useState(false);
  const [ isDeactivated, setIsDeactivated ] = useState(false);

  const handleDeleteRows = () => {
    const id_list = selected.map((id, index) => {
      return {_id: id};
    })
    setElementToDelete(JSON.stringify(id_list));
    setTitleDeleteText(`<span class="bolder">¿Confirmar que desea Eliminar ${selected.length} usuario${selected.length > 1 ? "s" : ""}?</span>`);
    setDeleteElementOpen(true);
  }

  const handleActivateRows = () => {
    const id_list = selected.map((id, index) => {
      return {_id: id};
    })
    setElementToActivate(JSON.stringify(id_list));
    setTitleActivateText(`<span class="bolder">¿Confirmar que desea Activar ${selected.length} usuario${selected.length > 1 ? "s" : ""}?</span>`);
    setActivateElementOpen(true);
  }

  const handleDesactivateRows = () => {
    const id_list = selected.map((id, index) => {
      return {_id: id};
    })
    setElementToDeactivate(JSON.stringify(id_list));
    setTitleDeactivateText(`<span class="bolder">¿Confirmar que desea Licenciar ${selected.length} usuario${selected.length > 1 ? "s" : ""}?</span>`);
    setDeactivateElementOpen(true);
  }

  useEffect(() => {
    
    if(isDeleted){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToDelete(null);
      setTitleDeleteText('');
      setDeleteElementOpen(false);
      setIsDeleted(false);
      setSelected([]);
    }

    if(isActivated){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToActivate(null);
      setTitleActivateText('');
      setActivateElementOpen(false);
      setIsActivated(false);
      setSelected([]);
    }

    if(isDeactivated){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToDeactivate(null);
      setTitleDeactivateText('');
      setDeactivateElementOpen(false);
      setIsDeactivated(false);
      setSelected([]);
    }
  
    return () => {}
  }, [isDeleted, isActivated, isDeactivated])

  return (
    <ToolbarStyled
        {...colors}
        pancolors={panColors}
        numselected={numSelected}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >

      <DeleteElement 
        open={deleteElementOpen} 
        setOpen={setDeleteElementOpen} 
        title={titleDeleteText}
        setTitle={setTitleDeleteText}
        id={elementToDelete}
        scope="users"
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
      />

      <ActivateElement 
        open={activateElementOpen} 
        setOpen={setActivateElementOpen} 
        title={titleActivateText}
        setTitle={setTitleActivateText}
        id={elementToActivate}
        scope="users"
        isActivated={isActivated}
        setIsActivated={setIsActivated}
      />

      <DeactivateElement 
        open={deactivateElementOpen} 
        setOpen={setDeactivateElementOpen} 
        title={titleDeactivateText}
        setTitle={setTitleDeactivateText}
        id={elementToDeactivate}
        scope="users"
        isDeactivated={isDeactivated}
        setIsDeactivated={setIsDeactivated}
      />

      {numSelected > 0 ? (
        <></>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          { title }
        </Typography>
      )}
         
      {numSelected > 0 ? (
        <>

        <ButtonTextCustom 
            className="bt_bulk_delete"
            text="Eliminar"
            onClick={() => handleDeleteRows()}

            icon={<TrashIcon />}
            background={{ normal: "", hover: panColors.red }}
            border={{ normal: panColors.primary, hover: panColors.primary }}
            textcolor={{ normal: panColors.red, hover: panColors.red }}
        />

        <ButtonTextCustom 
            className="bt_bulk_activate"
            text="Activar"
            onClick={() => handleActivateRows()}
            style_type="normal"
            button_type="activate"

            icon={<UserActivateIcon />}
            background={{ normal: panColors.primary, hover: panColors.primary }}
            border={{ normal: panColors.primary, hover: panColors.primary }}
            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
        />
        <ButtonTextCustom 
            className="bt_bulk_desactivate"
            text={`Licenciar`}
            onClick={() => handleDesactivateRows()}
            style_type="normal"
            button_type="desactivate"

            icon={<UserDesactivateIcon />}
            background={{ normal: panColors.primary, hover: panColors.primary }}
            border={{ normal: panColors.primary, hover: panColors.primary }}
            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
        />
        </>
      ) : (
        <></>
        
      )}
    </ToolbarStyled>
  );
}


const DataTable = (props) => {

    const { headCells,
            title,
            data,
            getData,
            totalRows,
            filters,
            settings } = props;

  const navigate = useNavigate();
  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState([]);
  const [ titleDeleteText, setTitleDeleteText ] = useState("");
  const [ elementToDelete, setElementToDelete ] = useState("");
  const [ elementToReset, setElementToReset ] = useState("");
  const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ resetPasswordOpen, setResetPasswordOpen ] = useState(false);
  

  const { colors,
          panColors,
          clientData } = useContext(ConfigurationContext);
           
    useEffect(() => {
        
      if(data === null){
        getData(page, rowsPerPage, filters);
      }
      
      if(data && data.length){
        setRows(data);
      }
      return () => {}

    }, [data])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage, rowsPerPage, filters);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    getData(0, parseInt(event.target.value, 10), filters);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id, fullname) => {

    setElementToDelete(JSON.stringify([{_id: id}]));
    setTitleDeleteText(`<span class="bolder">¿Confirmar Eliminar al usuario</span> ${fullname}<span class="bolder">?</span>`);
    setDeleteElementOpen(true);

    return null;

  }

  const handleClearPassword = (id, fullname) => {
    
    setElementToReset(id);
    setResetPasswordOpen(true);

    return null;

  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  useEffect(() => {
    
    if(isDeleted){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToDelete(null);
      setTitleDeleteText('');
      setDeleteElementOpen(false);
      setIsDeleted(false);
    }
  
    return () => {}
  }, [isDeleted])
  
  
  if(data === null){
    return null;
  }

  return (
    <WrapperStyled pancolors={panColors}>
      
        <ResetPassword 
          open={resetPasswordOpen} 
          setOpen={setResetPasswordOpen} 
          title={""}
          setTitle={""}
          id={elementToReset}
          scope="users"
          isDeleted={null}
          setIsDeleted={null}
        />

        <DeleteElement 
          open={deleteElementOpen} 
          setOpen={setDeleteElementOpen} 
          title={titleDeleteText}
          setTitle={setTitleDeleteText}
          id={elementToDelete}
          scope="users"
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />

        <EnhancedTableToolbar 
            numSelected={selected.length} 
            selected={selected}
            setSelected={setSelected}
            title={title}
            colors={colors}
            panColors={panColors}
            setRows={setRows}
            getData={getData}
            page={page}
            rowsPerPage={rowsPerPage}
            filters={filters}
            clientData={clientData}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.length ? stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  
                    return (
                        <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`tr-${row._id}`}
                            selected={isItemSelected}
                        >

                            <TableCell padding="checkbox">
                                <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId, }}
                                />
                            </TableCell>

                            
                            {(settings.section_list_columns.filter(col => col.id === 'avatar')).length ?
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                <div className="inner_avatar">
                                <Avatar
                                  alt="Remy Sharp"
                                  src={row.avatar}
                                />
                                </div>
                                
                            </TableCell> : ""}
                            
                            {(settings.section_list_columns.filter(col => col.id === 'name')).length ?
                            <TableCell align="left" className="field_name">
                              {row.captain ? <Tooltip title={"Capitán"} arrow placement="top" enterTouchDelay={0}><div className="is_captain"><AiFillStar /></div></Tooltip> : ""}
                              {row.name ? row.name : "- - -"}
                            </TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'last_name')).length ?
                            <TableCell align="left">{row.last_name ? row.last_name : "- - -"}</TableCell> : ""}
                            
                            {(settings.section_list_columns.filter(col => col.id === 'dni')).length ?
                            <TableCell align="left">{row.dni ? row.dni : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'email')).length ?
                            <TableCell align="left">{row.email ? row.email : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'role')).length ?
                            <TableCell align="left">{row.role ? row.role?.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'area')).length ?
                            <TableCell align="left">{row.area ? row.area?.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'pos')).length ?
                            <TableCell align="left" className="store">
                              {(clientData.name_clients === "one_sp" && ("role" in row && row.role.code === "1") && row.pos.length > 1) && <div className='warning'><IoMdWarning /></div>}
                              {row.pos ? row.pos.length === 1 ? row.pos[0].code : "- - -" : "- - -"}
                            </TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'user_profile')).length ?
                            <TableCell align="left">{row.user_profile ? row.user_profile?.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'created_date')).length ?
                            <TableCell align="left">{row.created_date ? DateTime.fromISO(row.created_date).toFormat('dd/M/yyyy') : "- - -"}</TableCell> : ""}

                            
                            <TableCell align="center">
                                <div className="actions">
                                    {(clientData.name_clients === 'one_sp' && clientData?.user_role?.code !== '8' && (row?.role?.code === '5' || row?.role?.code === '4')) ? ""
                                      :
                                      process.env.REACT_APP_SCOPE === "dev" ?
                                      <Tooltip title={"Blanquear contraseña"} arrow placement="top" enterTouchDelay={0}>
                                          <div className='bt_view_single' onClick={() => handleClearPassword(row._id, row.name + " " + row.last_name)}>
                                              <PiPasswordBold />
                                          </div>
                                      </Tooltip> : ""
                                    }
                                    <Tooltip title={"Ver más"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_view_single' onClick={() => navigate(`/users/view/${row._id}`)}>
                                            <HiEye />
                                        </div>
                                    </Tooltip>

                                    {(clientData.name_clients === 'one_sp' && clientData?.user_role?.code !== '8' && (row?.role?.code === '5' || row?.role?.code === '4')) ? ""
                                      :
                                      (settings.actions.filter(col => col === 'update')).length ?
                                        <Tooltip title={"Editar"} arrow placement="top" enterTouchDelay={0}>
                                            <div className='bt_edit_single' onClick={() => navigate(`/users/edit/${row._id}`)}>
                                                <BiPencil />
                                            </div>
                                        </Tooltip> 
                                      : ""
                                    }
                                    
                                    {(settings.actions.filter(col => col === 'delete')).length ?
                                    <Tooltip title={"Eliminar"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_delete_single' onClick={() => handleDelete(row._id, row.name + " " + row.last_name)}>
                                            <BiTrash />
                                        </div>
                                    </Tooltip> : ""}

                                </div>
                            </TableCell>

                        </TableRow>
                    );
                  
                })
              :
              <div className='loader'>
                <LoaderComponent size={30} />  
              </div>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </WrapperStyled>
  );
}

export default DataTable;