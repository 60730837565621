import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { AuthContext,
         ContentContext,
         ConfigurationContext } from '../../../context';

import { UserIcon, 
         RoleIcon,
         AreaIcon,
         EmailIcon,
         MobileIcon,
         CountryIcon,
         LocalizationAreaIcon,
         TimezoneIcon,
         TrashIcon,
         Region1Icon } from '../../../helpers/icons';

import { dataObjectUser,
         validateDNI,
         validateUniqueID,
         getCitiesByCountry,
         validateParamName,
         validateEmail } from '../../../helpers/functions';

import Badge from '@mui/material/Badge';    
import ModalImageProfile from '../../../components/elements/Modals/ModalImageProfile';
import InputCustom from '../../../components/elements/InputCustom';
import InputCheckboxCustom from '../../../components/elements/InputCheckboxCustom';
import SelectCustom from '../../../components/elements/SelectCustom';
import LabelCustom from '../../../components/elements/LabelCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import LoaderComponent from '../../../components/elements/LoaderComponent';
import AditionalInformationElement from '../../../components/elements/AditionalInformationElement';
import FormTopMessage from '../../../components/elements/FormTopMessage';
import FormBottomMessage from '../../../components/elements/FormBottomMessage';
import UserPos from '../../../components/elements/UserPos';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ScheduleCreate from '../../../components/elements/Dialogs/ScheduleCreate';
import { BiSolidDownArrow } from "react-icons/bi";
import Modal from '@mui/material/Modal';

import 'react-image-crop/dist/ReactCrop.css';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .box_form{
        display: grid; 
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        .box_column{

        }
        .date_picker{
            width: 100%;
            input{
                padding: 9.5px 14px!important;
                color: #484D63;
                font-size: 16px;
            }
            
            fieldset{
                border-color: rgba(186,186,194,1)!important;
                border-width: 1px;
            }
        }
    }
    .user_image{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100px;
        margin-bottom: 25px;
        .add_profile_image{
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            align-items: flex-start;
            .bt_add_profile_image{
                height: auto;
                padding: 0;
                >.text{
                    margin-left: 0;
                }
            }
            .bt_delete_profile_image{
                height: auto;
                padding: 0;
                margin-top: 10px;
            }
            .aclaration_bt_add_profile_image{
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                margin-top: 10px;
            }
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
            &.organization{
                order: 3;
            }
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            :last-child{
                margin-bottom: 0;
            }
            .label_custom{
                &.label_birth{
                    margin-bottom: 5px;
                }
            }
        }
        .field_test_user,
        .field_can_use_panel,
        .field_captain{
            margin-bottom: 10px;
            .label_custom{
                margin-bottom: 2px;
            }
        }
        .field_captain{
            margin-bottom: 20px;
        }
        .box_user_pos{
            width: 100%;
            order: 7;
            margin-top: 25px;
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }
    .add_aditional_information{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;

        border-radius: 6px;
        border: 1px solid ${props => `rgb(${props.pancolors.middle_gray})`};
        box-sizing: border-box;
        padding: 10px 20px 10px 20px;
        &.error{
            border: 1px solid ${props => `rgb(${props.pancolors.red})`};
        }
        .bt_add_more_info,
        .bt_show_more_info{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            padding: 0;
        }
        .bt_add_more_info{
            padding-right: 20px;
        }
        .aditional_information_el{
            margin-bottom: 10px;
        }
        .header_aditional_information{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .aditional_badge{
                .MuiBadge-badge{
                    top: 21px!important;
                    color: #FFFFFF;
                    background-color: ${props => `rgb(${props.pancolors.dots})`};
                }
            }
        }
        .aditional_information_elements{
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            &.show{
                display: flex;
            }
            &.hide{
                display: none;
            }
        }
    }
`
const ModalResendStyled = styled(Modal)`
    z-index: 99999!important;
    .inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 600px;
        background-color: #FFFFFF;
        border: 2px solid #fff;
        box-sizing: border-box;
        padding: 15px;

        -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);
        box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);

        .text{
            font-size: 16px;
            line-height: 22px;
        }
        .bt_accept_resend{
            margin-top: 15px;
        }
    }
`

const Form = (props) => {

    const { data = null,
            action,
            loadingForm,
            setLoadingForm,
            success,
            setSuccess } = props;

    const { clientData } = useContext(ConfigurationContext);

            
    const { basicData,
            uploadFile,
            userLoginValidate,
            createUser,
            editUser,
            getPosToAsign } = useContext(ContentContext);
            
    const { panColors } = useContext(ConfigurationContext);

    const navigate = useNavigate();
    
    const { id } = useParams();

    let inputRef = useRef({});
    let validateIDRed = useRef();
    
    const [ aditionalInfo, setAditionalInfo ] = useState([]);
    const [ formData, setFormData ] = useState({});
    const [ validateStatus, setValidateStatus ] = useState(null);
    const [ scheduleCreateOpen, setScheduleCreateOpen ] = useState(false);
    const [ openModalImageProfile, setOpenModalImageProfile ] = useState(false);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ profileImage, setProfileImage ] = useState(null);
    const [ cities, setCities ] = useState(null);
    const [ userLoginCompare,  setUserLoginCompare ] = useState("");
    const [ haveErrorForm, setHaveErrorForm ] = useState(false);
    const [ errorFormMessage, setErrorFormMessage ] = useState({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"});
    const [ testUser, setTestUser ] = useState(false);
    const [ canUsePanel, setCanUsePanel ] = useState(false);
    const [ userPos, setUserPos ] = useState([]);
    const [ userPosError, setUserPosError ] = useState(false);
    const [ showAditionalInformationElements, setShowAditionalInformationElements ] = useState(false);
    const [ fixedPhone, setFixedPhone ] = useState(false);
    const [ openModalWarningResend, setOpenModalWarningResend ] = useState(false);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {
        
        getPosToAsign()
    
      return () => {}
    }, [])

    useEffect(() => {
        
        if(data){
            if(data.pos){
                setUserPos(data.pos)
            }
        }
    
      return () => {}
    }, [data])
    
    useEffect(() => {
        
        if(basicData){  
            setFormData(dataObjectUser(data, basicData.settings.users.section_fields, clientData));
            if(data){
                setTestUser(data.test_user);
                setCanUsePanel(data.can_use_panel);
                setAditionalInfo(data.additional);
            }
        }

        return () => {
            setFormData({});
        }

    }, [basicData])

    useEffect(() => {
        
        if(formData){
            if('avatar' in formData && formData.avatar.value !== ""){
                setProfileImage(formData.avatar.value);
            }
        }
     
        return () => {}

    }, [formData?.avatar])

    useEffect(() => {
        
        if(Object.keys(formData).length && basicData){
            if(formData.country.value !== ""){
                const cities_response = getCitiesByCountry(basicData.countries, formData.country.value);
                setCities(cities_response);
            }
        }
     
        return () => {}

    }, [formData, basicData])

    

    useEffect(() => {
      
        setProfileImage(croppedImage);
    
      return () => {}
    }, [croppedImage])
    
    useEffect(() => {
      
        if(success === true && action === "create"){
            resetForm();
        };
    
      return () => {}
    }, [success])

    
    const handleFormElementChange = (name, value, index = null, key = null) => {
        //console.log("xxxxxx", name, value)
        let updateFormData = {...formData};
        switch (name) {
            case 'captain':
                updateFormData[name].value = value;
                break;
            case 'test_user':
                setTestUser(!testUser);
                break;
            case 'can_use_panel':
                setCanUsePanel(!canUsePanel);
                break;
            case 'dni':

                if(!validateDNI(value)){
                    updateFormData['dni'].error = true;
                }else{
                    updateFormData['dni'].error = false;
                }
                updateFormData[name].value = value;

                break;
            
            case 'user_login':

                if(clientData.name_clients === 'one_sp'){

                    if(!validateDNI(value)){
                        updateFormData['user_login'].error = true;
                    }else{
                        if(!validateUniqueID(value)){
                            updateFormData['user_login'].error = true;
                        }else{
                            updateFormData['user_login'].error = false;
                        }
                    }
                }else{
                    if(!validateUniqueID(value)){
                        updateFormData['user_login'].error = true;
                    }else{
                        updateFormData['user_login'].error = false;
                    }
                }
                updateFormData[name].value = value;

                break;

            case 'birthday':

                updateFormData[name].value = dayjs(value).format('YYYY-MM-DD');
                
                break;

            case 'country':
               if(value){
                    const cities_response = getCitiesByCountry(basicData.countries, value);
                    setCities(cities_response);
                }else{
                    setCities(null);
                    updateFormData["city"].value = "";
                }

                updateFormData[name].value = value;
                
                break;
            
            case 'phone':
                
                let numericRegex = "";
                if(clientData.name_clients === 'one_sp'){
                    numericRegex = /^[0-9]*$/;///^\+?[0-9]*$/;///^\+[0-9]*$/;///^[0-9]+$/;
                    // console.log("+ + + + + + + + + + +");
                    // console.log(updateFormData[name].value.length +">"+ value.length);
                    // console.log("+ + + + + + + + + + +");
                    if(updateFormData[name].value.length !== 0 && updateFormData[name].value.length > value.length){
                        //console.log("&")
                        updateFormData[name].value = value;
                    }else{
                        if(value !== ""){
                            if(!numericRegex.test(value)){
                                return null;
                            }
                        }
                        if(value.length <= 10){
                            updateFormData[name].value = value;
                        }
                    }
                }else{
                    
                    numericRegex = /^\+?[0-9]*$/;///^\+[0-9]*$/;
                    if(value !== ""){
                        if(!numericRegex.test(value)){
                            return null;
                        }
                    }
                    updateFormData[name].value = value;

                }
                
                break;
                
            case 'role':
                if(clientData.name_clients === "one_sp"){
                    //Jefe de turno
                    if(value === "6494b1ef3a45120008116a24"){
                        let updateFormData = {...formData};
                        updateFormData["area"].value = "64948fef17fbb60008fdd5d7";
                        updateFormData["area"].disabled = true;
                        setFormData(updateFormData);
                    }else{
                        //Experto
                        if(value === "64948fef17fbb60008fdd5d8"){
                            let updateFormData = {...formData};
                            updateFormData["area"].disabled = false;
                            updateFormData["area"].value = "";
                            setFormData(updateFormData);
                        }
                    }
                    if(clientData.user_role.code === '8'){

                    }
                }
                updateFormData[name].value = value;  
                break;
            case 'resend':
                if(value){
                    setOpenModalWarningResend(true);
                }
                updateFormData[name].value = value;  
                break;
            default:
                updateFormData[name].value = value;
                break;
        }

        
        setFormData(updateFormData);
        
        return null;
    }

    const handleFormElementKeyDown = (key, name, value) => {

        let updateFormData = {...formData};
        switch (name) {
            case 'phone':

                if(key === "Backspace" || key === "Delete"){
                    handleFormElementChange(name, value, null, key);
                }
                
                break;
            default:
                updateFormData[name].value = value;
                break;
        }
        
        setFormData(updateFormData);
        
        return null;
    }
    
    const handleFormElementKeyUp = (event, name, value) => {
        
        if(formData.user_login.value === userLoginCompare){
            return null;
        }
        switch (name) {
            case 'user_login':
                setUserLoginCompare(value);
                if(!formData['user_login'].error){
                    if(value.length >= 5){

                        if(validateIDRed.current !== null){
                            clearTimeout(validateIDRed.current);
                        }

                        setValidateStatus("loading");
                        
                        validateIDRed.current = setTimeout(() => {
                            
                            userLoginValidate({user_login: value}).then(res => {
                                if(res.data.status <= 0){
                                    setValidateStatus(false);
                                    let updateFormData = {...formData};
                                    updateFormData.user_login.error = true;
                                    setFormData(updateFormData);
                                }else{
                                    
                                    setValidateStatus(true);
                                }
                            })

                        }, 3000);
                    }
                }else{
                    if(validateIDRed.current !== null){
                        setValidateStatus(null);
                        clearTimeout(validateIDRed.current);
                    }
                }             
                
                break;
                default:
                    break
            
        }

    }

    const handleSubmit = () => {

        let haveError = false;
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {
                if(formData[key].required && formData[key].visibility){
                    if(clientData.name_clients === 'one_sp'){

                        if(key !== 'role'){
                            if(!formData[key].value.length){
                                haveError = true;
                                updateFormData[key].error = true;
                            }else{
                                updateFormData[key].error = false;
                            }
                        }

                        if(clientData.user_role.code === '8' && key === 'role' ){
                            if(!formData[key].value.length){
                                haveError = true;
                                updateFormData[key].error = true;
                            }else{
                                updateFormData[key].error = false;
                            }
                        }

                    }else{
                        if(!formData[key].value.length){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }
                
                }

                if(basicData.settings.users.section_fields.pos.visibility){
                    if(basicData.settings.users.section_fields.pos.required){
                        if(!userPos.length){
                            haveError = true;
                            setUserPosError(true);
                        }else{
                            if(clientData.name_clients === 'one_sp'){
                                if(updateFormData["role"].value !== "6494b12090c83a00089bde56" && updateFormData["role"].value !== "6494c5eaf8cf9900084250dd" && updateFormData["role"].value !== "6494c715f8cf9900084251c5"){
                                    if(userPos.length > 1){
                                        haveError = true;
                                        updateFormData["role"].error = true;
                                        setUserPosError(true);
                                    }else{
                                        updateFormData["role"].error = false;
                                        setUserPosError(false);
                                    }
                                }else{
                                    setUserPosError(false);
                                }
                            }else{
                                setUserPosError(false);
                            }
                        }
                    }
                }

                if(clientData.name_clients !== "one_sp"){
                    if(aditionalInfo.length){

                        const aditionalInfo_updated = aditionalInfo.map((aditional, i) => {
                            if(!aditional["label"].length){
                                haveError = true;
                                aditional["label_error"] = true;
                            }else{
                                if(!validateParamName(aditional["label"])){
                                    haveError = true;
                                    aditional["label_error"] = true;
                                }else{
                                    aditional["label_error"] = false;
                                }
                            }
                            if(!aditional["value"] || !aditional["value"].length){
                                haveError = true;
                                aditional["value_error"] = true;
                            }else{
                                aditional["value_error"] = false;
                            }
                            return aditional;
                        })
                        setAditionalInfo(aditionalInfo_updated);

                    }
                }

                if(formData.validate_drop.visibility){
                    updateFormData["email"].error = false;
                    updateFormData["phone"].error = false;

                    if(!updateFormData["email"].value.length && !updateFormData["phone"].value.length){
                        haveError = true;
                        updateFormData["email"].error = true;
                        updateFormData["phone"].error = true;
                    }else{
                        if(updateFormData["validate_drop"].value === "phone"){
                            if(!updateFormData["phone"].value.length){
                                haveError = true;
                                updateFormData["phone"].error = true;
                            }
                        }
                        if(updateFormData["validate_drop"].value === "email"){
                            if(!updateFormData["email"].value.length){
                                haveError = true;
                                updateFormData["email"].error = true;
                            }
                        }
                    }

                }

                if(updateFormData["email"].value.length){
                    if(!validateEmail(updateFormData["email"].value)){
                        haveError = true;
                        updateFormData["email"].error = true;
                    }
                    
                }
                
                if(action === "create"){
                    if(clientData.name_clients === "one_sp"){
                        if(key === "user_login"){
                            console.log("validateDNI -> ", !validateDNI(updateFormData["user_login"].value))
                            if(!validateDNI(updateFormData["user_login"].value)){
                                haveError = true;
                                updateFormData['user_login'].error = true;
                            }else{
                                updateFormData['user_login'].error = false;
                            }
                        }
                    }
                }

                if(clientData.name_clients === "one_sp"){
                    if(updateFormData['phone'].value.startsWith('+549') || updateFormData['phone'].value.startsWith('+54') || updateFormData['phone'].value.startsWith('+5') || updateFormData['phone'].value.startsWith('+')){
                        haveError = true;
                        updateFormData['phone'].error = true;
                    }else{
                        updateFormData['phone'].error = false;
                    }
                }
            
            })

            setErrorFormMessage({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"})
            setHaveErrorForm(haveError);

            

            if(!haveError){
                
                if(croppedImage){
                    setLoadingForm(true);
                    const uploaded = uploadFile(croppedImage, uploadRoute);
                    uploaded.then(res => {
                        updateFormData["avatar"].value = res;
                        handleSubmitForm(updateFormData, aditionalInfo);
                        setFormData(updateFormData);
                    })
                }else{
                    if(action == "edit"){
                        //console.log("updateFormData.resend", updateFormData.resend)
                        if(updateFormData.resend.value){
                            //alert("asd")
                            handleSubmitFormResend(updateFormData, aditionalInfo);
                        }else{
                            handleSubmitForm(updateFormData, aditionalInfo);
                        }
                    }else{
                        handleSubmitForm(updateFormData, aditionalInfo);
                    }
                    setFormData(updateFormData);
                }
                
            }else{
                
                setFormData(updateFormData);

            }

        }

    }

    const handleSubmitFormResend = (_payload, aditionalInfo) => {

        setLoadingForm(true);

        let request;
        let payload = {};

        formData.email.visibility && (payload.email = _payload.email.value);

        let phone = _payload.phone.value;
        if(clientData.name_clients === "one_sp"){
            if(_payload.phone.value.length){
                if(!_payload.phone.value.startsWith('+549')){
                    phone = "+549"+phone
                }
            }
        }

        formData.phone.visibility && (payload.phone = phone);
        formData.validate_drop.visibility && (payload.validation = _payload.validate_drop.value);

        if(formData.validate_drop.visibility){
            if(formData.resend.visibility){
                payload.reSend = _payload.resend.value ? true : false;
            }
        }

        payload.user_id = id;
        request = editUser(payload);

        request.then(res => {

            handleSubmitForm(_payload, aditionalInfo);
            /*
            if(res.data.status > 0){
                setLoadingForm(false);
                setSuccess(true);
            }else{
                setErrorFormMessage({top: res.data.message, bottom: res.data.message})
                setHaveErrorForm(true);
                setLoadingForm(false);
            }
            */
        }).catch(err => {
            
            setErrorFormMessage({top: err.message, bottom: err.message})
            setHaveErrorForm(true);
            setLoadingForm(false);

        })

    }

    const handleSubmitForm = (_payload, aditionalInfo) => {
        //return null;
        setLoadingForm(true);

        let aditionalInfoFinal = [];

        if(aditionalInfo.length > 0 ) {
            aditionalInfoFinal = aditionalInfo.map(info => {
                if(info.id && info.id.length < 30){
                    return {id: info.id, label: info.label, value: info.value};
                }else{
                    return {label: info.label, value: info.value};
                }
            })
        }
        
        let request;
        let payload = {};

        basicData.settings.users.section_fields.test_user.visibility && (payload.test_user = testUser);
        basicData.settings.users.section_fields.can_use_panel.visibility && (payload.can_use_panel = canUsePanel);
        formData.name.visibility && (payload.name = _payload.name.value);
        formData.last_name.visibility && (payload.last_name = _payload.last_name.value);
        formData.code.visibility && (payload.code = _payload.code.value);
        formData.dni.visibility && (payload.dni = _payload.dni.value);
        formData.area.visibility && (payload.area = _payload.area.value);
        formData.role.visibility && (payload.role = _payload.role.value);
        formData.department.visibility && (payload.department_id = _payload.department.value ? _payload.department.value : "");
        formData.flag.visibility && (payload.flag_id = _payload.flag.value ? _payload.flag.value : "");
        formData.region.visibility && (payload.region = _payload.region.value);
        formData.avatar.visibility && (payload.avatar = _payload.avatar.value);
        formData.gender.visibility && (payload.gender = _payload.gender.value ? _payload.gender.value : "");
        formData.prefix.visibility && (payload.prefix = _payload.prefix.value);
        formData.email.visibility && (payload.email = _payload.email.value);

        let phone = _payload.phone.value;
        if(clientData.name_clients === "one_sp"){
            if(_payload.phone.value.length){
                if(!_payload.phone.value.startsWith('+549')){
                    phone = "+549"+phone
                }
            }
        }

        formData.phone.visibility && (payload.phone = phone);//_payload.phone.value

        formData.validate_drop.visibility && (payload.validation = _payload.validate_drop.value);

        if(formData.validate_drop.visibility){
            if(action == "edit"){
                payload.reSend = false;
                /*
                if(formData.resend.visibility){
                    payload.reSend = _payload.resend.value ? true : false;
                }
                */
            }
            if(action == "create"){
                if(formData.resend.visibility){
                    payload.reSend = _payload.resend.value ? true : false;
                }
            }
        }
        

        formData.country.visibility && (payload.country = _payload.country.value);
        formData.city.visibility && (payload.city = _payload.city.value);
        formData.language.visibility && (payload.language = _payload.language.value);
        formData.time_zone.visibility && (payload.time_zone = _payload.time_zone.value);
        formData.birthday.visibility && (payload.birthday = dayjs(_payload.birthday.value).isValid() ? _payload.birthday.value : "");
        formData.captain.visibility && (payload.captain = _payload.captain.value ? _payload.captain.value : false);
        basicData.settings.users.section_fields.pos.visibility && (payload.pos = JSON.stringify(formatUserPos(userPos)));
        basicData.settings.users.section_fields.additional.visibility && (payload.additional = JSON.stringify(aditionalInfoFinal));
        
        if(action == "create"){
            if(clientData.name_clients === "one_sp"){
                payload.dni = _payload.user_login.value;
            }
            formData.user_login.visibility && (payload.user_login = _payload.user_login.value);
            request = createUser(payload);
        }
        if(action == "edit"){
            payload.user_id = id;
            request = editUser(payload);
        }
            
        request.then(res => {
                
                if(res.data.status > 0){
                    setLoadingForm(false);
                    setSuccess(true);
                }else{
                    
                    let message_text = res.data.message;
                    if("update" in res.data){
                        message_text = res.data.update.message;
                    }
                    setErrorFormMessage({top: message_text, bottom: message_text})
                    setHaveErrorForm(true);
                    setLoadingForm(false);
                    
                }
            }).catch(err => {
                
                setErrorFormMessage({top: err.message, bottom: err.message})
                setHaveErrorForm(true);
                setLoadingForm(false);
            })
        
    }

    const formatUserPos = (userPos) => {
        const update_user_pos = userPos.map((pos, index) => {
            return {_id: pos};
        })
        return update_user_pos;
    }

    const handleCancel = () => {
        navigate("/users");
    }
    
    const handleAddAditionalInformation = () => {
        const _uuid = uuid();
        setShowAditionalInformationElements(true);
        setAditionalInfo(aditionalInfo => [{id: _uuid, label: "", value: ""}, ...aditionalInfo]);
    }
    
    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })
        setAditionalInfo([]);
        setOpenModalImageProfile(false);
        setCroppedImage(null);
        setUploadRoute(null);
        setProfileImage(null);
        setValidateStatus(null);

    }
    
    const handleDeleteProfileImage = () => {

        setCroppedImage(null);
        setProfileImage(null);

        const remove_image = {...formData};
        remove_image.avatar.value = "";
        setFormData(remove_image);

    }

    const handleDataRole = (basicData) => {

        if(clientData.name_clients === 'one_sp'){
            let role_list = [];
            if(clientData.user_role.code !== '8'){
                const roles_updates = {...basicData};
                role_list = roles_updates.roles.filter(rol => rol._id !== "6494b12090c83a00089bde56" && rol._id !== "6494c5eaf8cf9900084250dd" && rol._id !== "6494c715f8cf9900084251c5" && rol._id !== "6494c299b63e0a0008de6617");
                return role_list;
            }else{
                return basicData.roles;
            }
        }

    }

    const handleShowAddAditionalInformation = () => {
        setShowAditionalInformationElements(!showAditionalInformationElements);
    }

    const handleCloseModalWarningResend = () => {
        setOpenModalWarningResend(!openModalWarningResend);
    }

    useEffect(() => {

        if(!fixedPhone){
            if(Object.keys(formData).length && Object.keys(clientData).length){
                if(clientData.name_clients === 'one_sp'){
                    if(action === "edit"){
                        const prefix = '+549';
                        let updateFormData = {...formData};
                        if(updateFormData.phone.value){
                            if(updateFormData.phone.value.toString().startsWith(prefix)){
                                updateFormData.phone.value = formData.phone.value.substr(prefix.length);
                                //phone = "+549"+phone
                            }
                        }
                        setFixedPhone(true);
                        setFormData(updateFormData);
                    }
                }
                
            }
        }

      return () => {}
    }, [formData, clientData, fixedPhone])
    

    if(basicData){
        if('settings' in basicData){
            
        }else{
            return null;
        }
        
    }else{
        return null;
    }
    

    return (
        <>
            <ModalResendStyled
                open={openModalWarningResend}
                onClose={null}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='inner'>
                    <div className='text'>
                        Tildar esta opción implica el reseteo de la contraseña del usuario y el envio de una contraseña temporal al metodo de validación especificado.
                    </div>
                    <ButtonCustom 
                        className="bt_accept_resend"
                        text={"Aceptar"}
                        onClick={handleCloseModalWarningResend}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                </div>
            </ModalResendStyled>

            {openModalImageProfile &&
                <ModalImageProfile 
                    openModalImageProfile={openModalImageProfile} 
                    setOpenModalImageProfile={setOpenModalImageProfile} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                />
            }
            <ScheduleCreate open={scheduleCreateOpen} setOpen={setScheduleCreateOpen} />
            
            {!Object.keys(formData).length && !Object.keys(basicData).length && !Object.keys(clientData).length
            ?
            <>carga</>
            :
            <WrapperStyled
                pancolors={panColors}
            >
                
                <FormTopMessage 
                    haveError={haveErrorForm}
                    text={errorFormMessage.top}
                />

                <div className="box_form">
                    <div className="box_column">
                        <div className="user_image">
                            <Avatar
                                alt="Remy Sharp"
                                src={profileImage}
                                sx={{ width: 100, height: 100 }}
                            />
                            <div className="add_profile_image">
                                <ButtonTextCustom 
                                    className="bt_add_profile_image"
                                    text="+ Agregar / Editar foto de perfil"
                                    onClick={() => setOpenModalImageProfile(true)}

                                    icon={null}
                                    background={{ normal: "", hover: "" }}
                                    border={{ normal: "", hover: "" }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                />
                                {profileImage &&
                                    <ButtonTextCustom 
                                        className="bt_delete_profile_image"
                                        text="Borrar"
                                        onClick={() => handleDeleteProfileImage()}

                                        icon={<TrashIcon />}
                                        background={{ normal: "", hover: "" }}
                                        border={{ normal: "", hover: "" }}
                                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                                    />
                                }
                            </div>
                        </div>
                        <div className="box_fields">

                            <div className="title">
                                Datos personales
                            </div>
                            {basicData.settings.users.section_fields.test_user.visibility ?
                            <InputCheckboxCustom 
                                name={"test_user"}
                                label={"Usuario de prueba"}
                                placeholder={""}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_test_user`}
                                type="text"
                                value={testUser}
                                help={""}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={""}
                                haveError={null}
                            /> : ""}
                            
                            {basicData.settings.users.section_fields.can_use_panel.visibility ?
                            <InputCheckboxCustom 
                                name={"can_use_panel"}
                                label={"Acceso al panel"}
                                placeholder={""}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_can_use_panel`}
                                type="text"
                                value={canUsePanel}
                                help={""}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={""}
                                haveError={null}
                            /> : ""}

                            {basicData.settings.users.section_fields.captain.visibility ?
                            <InputCheckboxCustom 
                                name={formData?.captain?.ref}
                                label={formData?.captain?.label}
                                placeholder={formData?.captain?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.captain?.ref}${formData?.captain?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.captain?.value}
                                help={formData?.captain?.help}
                                isRequired={formData?.captain?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={formData?.captain?.tooltip}
                                haveError={formData?.captain?.error}
                            /> : ""}

                            {formData?.last_name?.visibility ? 
                            <InputCustom 
                                name={formData?.last_name?.ref}
                                label={formData?.last_name?.label}
                                placeholder={formData?.last_name?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.last_name?.ref}${formData?.last_name?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.last_name?.value}
                                help={formData?.last_name?.help}
                                isRequired={formData?.last_name?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.last_name?.tooltip}
                                haveError={formData?.last_name?.error}
                            /> : ""}

                            {formData?.name?.visibility ?
                            <InputCustom 
                                name={formData?.name?.ref}
                                label={formData?.name?.label}
                                placeholder={formData?.name?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.name?.ref}${formData?.name?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.name?.value}
                                help={formData?.name?.help}
                                isRequired={formData?.name?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.name?.tooltip}
                                haveError={formData?.name?.error}
                            /> : ""}
                            
                            {formData?.birthday?.visibility ? 
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="field">
                                <LabelCustom
                                    name="birth"
                                    label="Fecha de Nacimiento"
                                    onChange={null}
                                    className="field birthday"
                                    styles={ formStyle }
                                    label_icon={<UserIcon />}
                                    isRequired={formData?.birthday?.required}
                                />
                                <DatePicker
                                    value={dayjs(formData?.birthday?.value)}
                                    onChange={(newValue) => handleFormElementChange("birthday", newValue)}
                                    format="DD/MM/YYYY"
                                    className="date_picker"
                                />
                            </div>
                            </LocalizationProvider> : ""}
                            
                            {formData?.gender?.visibility ? 
                            <SelectCustom 
                                name={formData?.gender?.ref}
                                label={formData?.gender?.label}
                                placeholder={formData?.gender?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${formData?.gender?.ref}${formData?.gender?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.gender?.value}
                                help={formData?.gender?.help}
                                isRequired={formData?.gender?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.gender?.tooltip}
                                haveError={formData?.gender?.error}
                                data={basicData?.gender}
                            /> : ""}

                            {formData?.prefix?.visibility ? 
                            <SelectCustom 
                                name={formData?.prefix?.ref}
                                label={formData?.prefix?.label}
                                className={`field field_${formData?.prefix?.ref}${formData?.prefix?.error ? " error" : ""}`}
                                value={formData?.prefix?.value}
                                isRequired={formData?.prefix?.required}
                                placeholder={formData?.prefix?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.prefix?.help}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.prefix?.tooltip}
                                haveError={formData?.prefix?.error}
                                data={basicData?.prefix}
                            /> : ""}

                            {formData?.code?.visibility ?
                            <InputCustom 
                                name={formData?.code?.ref}
                                label={formData?.code?.label}
                                placeholder={formData?.code?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.code?.ref}${formData?.code?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.code?.value}
                                help={formData?.code?.help}
                                isRequired={formData?.code?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.code?.tooltip}
                                haveError={formData?.code?.error}
                            /> : ""}
                        </div>
                    </div>

                    <div className="box_column">
                        <div className="box_fields">

                            {formData?.dni?.visibility ?
                            <InputCustom 
                                name={formData?.dni?.ref}
                                label={formData?.dni?.label}
                                className={`field field_${formData?.dni?.ref}${formData?.dni?.error ? " error" : ""}`}
                                value={formData?.dni?.value}
                                isRequired={formData?.dni?.required}
                                placeholder={formData?.dni?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                type="text"
                                help={formData?.dni?.help}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                //tooltip_content={formData?.dni?.tooltip}
                                tooltip_content={clientData.name_clients === "one_sp" ? formData?.dni?.tooltip : ''}
                                haveError={formData?.dni?.error}
                                order={1}
                            /> : ""}

                            {formData?.user_login?.visibility ?
                            <InputCustom 
                                name={formData?.user_login?.ref}
                                label={formData?.user_login?.label}
                                className={`field field_${formData?.user_login?.ref}${formData?.user_login?.error ? " error" : ""}`}
                                value={formData?.user_login?.value}
                                isRequired={formData?.user_login?.required}
                                placeholder={formData?.user_login?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyUp={handleFormElementKeyUp}
                                type="text"
                                help={clientData.name_clients !== "one_sp" ? formData?.user_login?.help : ""}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={clientData.name_clients === "one_sp" ? formData?.user_login?.tooltip : formData?.dni?.tooltip}
                                //tooltip_content={formData?.user_login?.tooltip}
                                haveError={formData?.user_login?.error}
                                disabled={data ? true : false}

                                validateStatus={validateStatus}
                                setValidateStatus={setValidateStatus}
                                order={2}
                            /> : ""}

                            <div className="title organization">
                                Datos de organización
                            </div>

                            {formData?.area?.visibility ?
                            <SelectCustom 
                                name={formData?.area?.ref}
                                label={formData?.area?.label}
                                className={`field field_${formData?.area?.ref}${formData?.area?.error ? " error" : ""}`}
                                value={formData?.area?.value}
                                isRequired={formData?.area?.required}
                                disabled={formData?.area?.disabled}
                                placeholder={formData?.area?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.area?.help}
                                styles={ formStyle }
                                label_icon={<AreaIcon />}
                                tooltip_content={formData?.area?.tooltip}
                                haveError={formData?.area?.error}
                                data={basicData?.areas}
                                order={clientData.name_clients === "one_sp" ? 5 : 4}
                            /> : ""}

                            {clientData.name_clients === "one_sp" ?
                                <SelectCustom 
                                    name={formData?.role?.ref}
                                    label={formData?.role?.label}
                                    className={`field field_${formData?.role?.ref}${formData?.role?.error ? " error" : ""}`}
                                    value={formData?.role?.value}
                                    isRequired={formData?.role?.required}
                                    placeholder={formData?.role?.placeholder}
                                    onChange={handleFormElementChange}
                                    type="text"
                                    help={formData?.role?.help}
                                    styles={ formStyle }
                                    label_icon={<RoleIcon />}
                                    tooltip_content={formData?.role?.tooltip}
                                    haveError={formData?.role?.error}
                                    data={handleDataRole(basicData)/*basicData?.roles*/}
                                    order={clientData.name_clients === "one_sp" ? 4 : 5}
                                />
                            :
                                <SelectCustom 
                                    name={formData?.role?.ref}
                                    label={formData?.role?.label}
                                    className={`field field_${formData?.role?.ref}${formData?.role?.error ? " error" : ""}`}
                                    value={formData?.role?.value}
                                    isRequired={formData?.role?.required}
                                    placeholder={formData?.role?.placeholder}
                                    onChange={handleFormElementChange}
                                    type="text"
                                    help={formData?.role?.help}
                                    styles={ formStyle }
                                    label_icon={<RoleIcon />}
                                    tooltip_content={formData?.role?.tooltip}
                                    haveError={formData?.role?.error}
                                    data={basicData?.roles}
                                    order={clientData.name_clients !== "one_sp" ? 4 : 5}
                                />
                            }

                            {formData?.region?.visibility ?
                            <SelectCustom 
                                name={formData?.region?.ref}
                                label={formData?.region?.label}
                                className={`field field_${formData?.region?.ref}${formData?.region?.error ? " error" : ""}`}
                                value={formData?.region?.value}
                                isRequired={formData?.region?.required}
                                placeholder={formData?.region?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.region?.help}
                                styles={ formStyle }
                                label_icon={<Region1Icon />}
                                tooltip_content={formData?.region?.tooltip}
                                haveError={formData?.region?.error}
                                data={basicData?.regions}
                                order={6}
                            /> : ""}
                            
                            {basicData.settings.users.section_fields.pos.visibility ?
                            <div className='box_user_pos'>
                                <UserPos 
                                    userPosError={userPosError}
                                    userPos={userPos}
                                    setUserPos={setUserPos}
                                    role={formData?.role?.value}
                                />
                            </div> : ""}

                            {formData?.department?.visibility ?
                            <SelectCustom 
                                name={formData?.department?.ref}
                                label={formData?.department?.label}
                                className={`field field_${formData?.department?.ref}${formData?.department?.error ? " error" : ""}`}
                                value={formData?.department?.value}
                                isRequired={formData?.department?.required}
                                placeholder={formData?.department?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.department?.help}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={formData?.department?.tooltip}
                                haveError={formData?.department?.error}
                                data={basicData?.departments}
                                order={4}
                            /> : ""}
                            
                            {formData?.flag?.visibility ?
                            <SelectCustom 
                                name={formData?.flag?.ref}
                                label={formData?.flag?.label}
                                className={`field field_${formData?.flag?.ref}${formData?.flag?.error ? " error" : ""}`}
                                value={formData?.flag?.value}
                                isRequired={formData?.flag?.required}
                                placeholder={formData?.flag?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.flag?.help}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={formData?.flag?.tooltip}
                                haveError={formData?.flag?.error}
                                data={basicData?.flags}
                                order={4}
                            /> : ""}

                        </div>
                        
                    </div>

                    <div className="box_column">
                        <div className="box_fields">

                            <div className="title">
                                Datos de contacto
                            </div>

                            {formData?.email?.visibility ?
                            <InputCustom 
                                name={formData?.email?.ref}
                                label={formData?.email?.label}
                                className={`field field_${formData?.email?.ref}${formData?.email?.error ? " error" : ""}`}
                                value={formData?.email?.value}
                                isRequired={formData?.email?.required}
                                placeholder={formData?.email?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                type="text"
                                help={formData?.email?.help}
                                styles={ formStyle }
                                label_icon={<EmailIcon />}
                                tooltip_content={formData?.email?.tooltip}
                                haveError={formData?.email?.error}
                            /> : ""}

                            {formData?.phone?.visibility ?
                            <InputCustom 
                                name={formData?.phone?.ref}
                                label={formData?.phone?.label}
                                className={`field field_${formData?.phone?.ref}${formData?.phone?.error ? " error" : ""}`}
                                value={formData?.phone?.value}
                                isRequired={formData?.phone?.required}
                                placeholder={formData?.phone?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}//handleFormElementKeyDown
                                type="text"
                                help={formData?.phone?.help}
                                styles={ formStyle }
                                label_icon={<MobileIcon />}
                                tooltip_content={formData?.phone?.tooltip}
                                haveError={formData?.phone?.error}
                                prefix={clientData.name_clients === 'one_sp' ? "+549" : null}
                            /> : ""}

                            {formData?.validate_drop?.visibility ?
                            <SelectCustom 
                                name={formData?.validate_drop?.ref}
                                label={formData?.validate_drop?.label}
                                className={`field field_${formData?.validate_drop?.ref}${formData?.validate_drop?.error ? " error" : ""}`}
                                value={formData?.validate_drop?.value}
                                isRequired={formData?.validate_drop?.required}
                                placeholder={formData?.validate_drop?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.validate_drop?.help}
                                styles={ formStyle }
                                label_icon={<CountryIcon />}
                                tooltip_content={formData?.validate_drop?.tooltip}
                                haveError={formData?.validate_drop?.error}
                                data={basicData?.validate_user_options}
                            /> : ""}



                            {(formData?.validate_drop?.visibility && formData?.resend?.visibility) && action === "edit" ?
                            <InputCheckboxCustom 
                                name={formData?.resend?.ref}
                                label={formData?.resend?.label}
                                placeholder={formData?.resend?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.resend?.ref}${formData?.resend?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.resend?.value}
                                help={formData?.resend?.help}
                                isRequired={formData?.resend?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={formData?.resend?.tooltip}
                                haveError={formData?.resend?.error}
                            /> : ""}

                            {(formData?.country?.visibility || formData?.city?.visibility || formData?.language?.visibility || formData?.time_zone?.visibility) ? 
                            <div className="title">
                                Información de localización
                            </div> : ""}

                            {formData?.country?.visibility ?
                            <SelectCustom 
                                name={formData?.country?.ref}
                                label={formData?.country?.label}
                                className={`field field_${formData?.country?.ref}${formData?.country?.error ? " error" : ""}`}
                                value={formData?.country?.value}
                                isRequired={formData?.country?.required}
                                placeholder={formData?.country?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.country?.help}
                                styles={ formStyle }
                                label_icon={<CountryIcon />}
                                tooltip_content={formData?.country?.tooltip}
                                haveError={formData?.country?.error}
                                data={basicData?.countries}
                            /> : ""}

                            {formData?.city?.visibility ?
                            <SelectCustom 
                                name={formData?.city?.ref}
                                label={formData?.city?.label}
                                className={`field field_${formData?.city?.ref}${formData?.city?.error ? " error" : ""}`}
                                value={formData?.city?.value}
                                isRequired={formData?.city?.required}
                                placeholder={formData?.city?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.city?.help}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.city?.tooltip}
                                haveError={formData?.city?.error}
                                disabled={cities ? false : true}
                                data={cities}
                            /> : ""}

                            {formData?.language?.visibility ?
                            <SelectCustom 
                                name={formData?.language?.ref}
                                label={formData?.language?.label}
                                className={`field field_${formData?.language?.ref}${formData?.language?.error ? " error" : ""}`}
                                value={formData?.language?.value}
                                isRequired={formData?.language?.required}
                                placeholder={formData?.language?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.language?.help}
                                styles={ formStyle }
                                label_icon={<LocalizationAreaIcon />}
                                tooltip_content={formData?.language?.tooltip}
                                haveError={formData?.language?.error}
                                data={basicData?.language}
                            /> : ""}

                            {formData?.time_zone?.visibility ?
                            <SelectCustom 
                                name={formData?.time_zone?.ref}
                                label={formData?.time_zone?.label}
                                className={`field field_${formData?.time_zone?.ref}${formData?.time_zone?.error ? " error" : ""}`}
                                value={formData?.time_zone?.value}
                                isRequired={formData?.time_zone?.required}
                                placeholder={formData?.time_zone?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.time_zone?.help}
                                styles={ formStyle }
                                label_icon={<TimezoneIcon />}
                                tooltip_content={formData?.time_zone?.tooltip}
                                haveError={formData?.time_zone?.error}
                                data={basicData?.time_zone}
                            /> : ""}

                        </div>
                    </div>
                </div>
                
                {basicData.settings.users.section_fields.additional.visibility ?
                <div className={`add_aditional_information ${(aditionalInfo.filter(a => a?.value_error === true)).length ? 'error' : ''}`}>
                    <div className='header_aditional_information'>
                        <Badge badgeContent={aditionalInfo.length} color="secondary" className="aditional_badge">
      
                        <ButtonTextCustom 
                            className="bt_add_more_info"
                            text="+ Agregar Información adicional"
                            onClick={() => handleAddAditionalInformation()}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                        />
                        </Badge>
                        {aditionalInfo.length ?
                        <ButtonTextCustom 
                            className="bt_show_more_info"
                            text={`${showAditionalInformationElements ? 'Cerrar' : 'Abrir'}`}
                            onClick={() => handleShowAddAditionalInformation()}

                            icon={<BiSolidDownArrow />}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                        /> : "" }
                    </div>
                    
                    <div className={`aditional_information_elements ${showAditionalInformationElements ? aditionalInfo.length ? 'show' : 'hide' : 'hide'}`}>
                        {aditionalInfo &&
                            aditionalInfo.map((el, i) => {
                                return(
                                    <AditionalInformationElement 
                                        element={el}
                                        index={i}
                                        aditionalInfo={aditionalInfo}
                                        setAditionalInfo={setAditionalInfo}
                                        className="aditional_information_el"
                                    />
                                )
                            })
                        }
                    </div>

                </div> : ""}

                <FormBottomMessage 
                    className="form_error_bottom"
                    haveError={haveErrorForm}
                    text={errorFormMessage.bottom}
                />

                <div className="actions">
                    {!loadingForm ?
                        <ButtonCustom 
                            className="bt_submit"
                            text={action == "create" ? "Crear Usuario" : "Guardar Usuario"}
                            onClick={handleSubmit}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        :
                        <div className='loader'>
                            <LoaderComponent size={25} color={panColors.white} />
                        </div>
                    }
                    <ButtonTextCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => { !loadingForm && handleCancel() } }

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                        disabled={loadingForm}
                    />
                </div>
                
            </WrapperStyled>
            }
        </>
    )
                
}

export default Form;
