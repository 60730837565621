import React, { useContext } from 'react';
import InputCustom from '../elements/InputCustom';
import TextareaCustom from '../elements/TextareaCustom';
import { ConfigurationContext } from '../../context';
import { TitleIcon } from '../../helpers/icons';
import { HeadlineIcon } from '../../helpers/icons';

const BoxSurveyData = (props) => {

    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);

    const {lang, dataSurveyByLang, setDataSurveyByLang, showLanguageData } = props;

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    console.log(lang);

        const handleDataSurveyChange = (name, value) => {
            //console.log("setDataSurveyByLang", name, value)
            let updateData = {...dataSurveyByLang};
    
            switch (name) {
                case 'name':
                    updateData[lang.language_identifier][name].value = value;
                    break;
                case 'description':
                    updateData[lang.language_identifier][name].value = value;
                    break;
                default:
                    //updateData[name].value = value;
                    break;
            }
            //dataSurveyByLang[lang.language_identifier]

            setDataSurveyByLang(updateData);
        }

        if(!dataSurveyByLang){
            return null;
        }

    return (
        <div className={`question_type_container content_form${showLanguageData === lang.language_identifier ? ' show' : ' hide'}`}>
                                                    
            <div className="field box_field_name">
                <InputCustom 
                    name={dataSurveyByLang[lang.language_identifier].name.ref}
                    label={dataSurveyByLang[lang.language_identifier].name.label}
                    placeholder={dataSurveyByLang[lang.language_identifier].name.placeholder}
                    onChange={handleDataSurveyChange}
                    onKeyDown={null}
                    className={`field field_${dataSurveyByLang[lang.language_identifier].name.ref}${dataSurveyByLang[lang.language_identifier].name.error ? " error" : ""}`}
                    type="text"
                    value={dataSurveyByLang[lang.language_identifier].name.value}
                    help={""}
                    isRequired={dataSurveyByLang[lang.language_identifier].name.required}
                    styles={ formStyle }
                    label_icon={<TitleIcon />}
                    tooltip_content={dataSurveyByLang[lang.language_identifier].name.tooltip}
                    haveError={dataSurveyByLang[lang.language_identifier].name.error}
                />
            </div>
            <div className="field box_field_description">
                <TextareaCustom 
                    name={dataSurveyByLang[lang.language_identifier].description.ref}
                    label={dataSurveyByLang[lang.language_identifier].description.label}
                    placeholder={dataSurveyByLang[lang.language_identifier].description.placeholder}
                    onChange={handleDataSurveyChange}
                    onKeyDown={null}
                    className={`field field_${dataSurveyByLang[lang.language_identifier].description?.ref}${dataSurveyByLang[lang.language_identifier].description?.error ? " error" : ""}`}
                    type="text"
                    value={dataSurveyByLang[lang.language_identifier].description?.value}
                    help={""}
                    isRequired={dataSurveyByLang[lang.language_identifier].description?.required}
                    styles={ formStyle }
                    label_icon={<HeadlineIcon />}
                    tooltip_content={dataSurveyByLang[lang.language_identifier].description?.tooltip}
                    haveError={dataSurveyByLang[lang.language_identifier].description?.error}
                />
            </div>

        </div>
    );
};

export default BoxSurveyData;